//button
.simple-btn {
	background: transparent;
	border: 0;
	cursor: pointer;
	display: inline-flex;
	align-content: center;
	align-items: center;
}

.button-lg {
	min-width: 160px !important;
	min-height: 50px;
}

.simple-btn .material-icons {
	margin-right: 0.5rem;
	font-size: 1rem;
}
.button-grey {
   background-color: #E0E0E0;
   transition: all 0.3s ease-in-out 0s;
   &:hover {
     background: $accent;
     color: #fff;
   }
 }

 .transparent-btn {
   border: 2px solid #d8d8d8;
   display: block;
   padding: 0.8rem;
   text-align: center;
   text-transform: uppercase;
   margin: 2.5rem 0;
   color: rgba(0, 0, 0, 0.54);
   font-weight: bold;
   cursor: pointer;
 }