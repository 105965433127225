*, ::after, ::before {
	box-sizing: border-box;
}
// Basics tags 
code {
  padding: 8px;
  background: $body-bg;
}

pre {
  background-color: $body-bg;
  padding: 15px;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

//Table
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table thead {
  text-align: left;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody+tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-responsive-x{
   // overflow-y: hidden;
   display: block;
   width: 100%;
   overflow-x: auto;
   -ms-overflow-style: -ms-autohiding-scrollbar; 
   .table-scroll-wrap{
      min-width:900px;
   } 
}
.table-responsive-y{
   width: 100%;
   overflow: hidden;
   overflow-y: auto;

}
.text-nowrap{
   white-space: nowrap
}
.overflow-hidden{
   overflow:hidden;
}

//Container
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width:576px) {
  .container {
    width: 540px
  }
}

@media (min-width:768px) {
  .container {
    width: 720px
  }
}

@media (min-width:992px) {
  .container {
    width: 960px
  }
}

@media (min-width:1200px) {
  .container {
    width: 1140px
  }
}

@media (min-width:1520px) {
  .container {
    width: 1450px
  }
}

//Row and col fix 
//If you dont wanna use this then You can use fxFlexGap to give margin to the column 
.col-gap {
  margin-left: -15px;
  margin-right: -15px;

  >div {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

//Block Title 
.block-title h2 {
  margin-bottom: 3rem;
}

// Section gap class between the home page sections
.section-gap {
  padding: 5rem 0;
}

.section-gap-lg {
  padding: 7rem 0;
}

//position
.relative {
  position: relative;
}

//
.w-100 {
  width: 100%;
}

// Horizontal Line
hr {
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 3rem 0;
}

.dark-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.spacer {
  border-bottom: 1px solid $theme-border-color;
  margin: 1.5rem 0;
}

.bg-grey {
  background: $grey-bg;
}

//Round Circle
.round-corner {
  border-radius: 100%;
}

//Page Title
.page-title-bar {
  padding: 7rem 0;
  text-align: $page-title-align;
  background-image: $page-title-bg-image;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: $page-title-color;

  * {
    color: $page-title-color;
  }
}

.position-relative {
  position: relative;
}

.shop-card-wrapper .mat-card {
  margin-bottom: 2rem;
  overflow: hidden;
}

//product detail
@media(max-width:959px) {
  .product-detail-content {
    margin-top: 3rem;
  }
}

@media(max-width:420px) {
  .detail-btns {
    button {
      margin-bottom: 1rem;
      display:block;
    }
  }
}

// mat-select-panel{
div.mat-select-panel{
   max-height:280px;
}

//cdk pan//review
.user-list-wrap {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
}
.alt-sec:nth-of-type(2n)>div:first-child {
  order: 1;
}
//team
embryo-team {
  width: 100% !important;
}
body app-main,body .mat-drawer-content {
  overflow-x:hidden;
  overflow-y: auto;
}
.user-switch-btn{
   position: fixed;
   right: 0;
   top: calc(50% - 4rem);
   z-index: 999;
   button{
      height: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;      
   }
}
.h-100{
   height: 100%;
}
.over-visible{
   overflow: visible !important;
}
.d-inline{
   display: inline !important;
}
.d-block{
   display: block !important;
}
.text-ellipse{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
