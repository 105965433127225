@charset "UTF-8";
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #283593;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #283593;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #283593;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff5722;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #283593;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff5722;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #283593;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff5722;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #283593;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff5722;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #283593;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff5722;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #283593;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff5722;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #283593;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff5722;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(40, 53, 147, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(40, 53, 147, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(40, 53, 147, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #283593;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(40, 53, 147, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(40, 53, 147, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #283593;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff5722;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #283593;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff5722;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff5722;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #283593;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #283593;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff5722;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #283593;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff5722;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #283593;
}
.mat-icon.mat-accent {
  color: #ff5722;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #283593;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff5722;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #c5cae9;
}

.mat-progress-bar-buffer {
  background-color: #c5cae9;
}

.mat-progress-bar-fill::after {
  background-color: #283593;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffccbc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffccbc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff5722;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #283593;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff5722;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #283593;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #283593;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #283593;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff5722;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #283593;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(40, 53, 147, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #283593;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #283593;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(40, 53, 147, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #ff5722;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #283593;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #283593;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff5722;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #283593;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #283593;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff5722;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff5722;
}

.unicorn-dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.unicorn-dark-theme .mat-option {
  color: white;
}
.unicorn-dark-theme .mat-option:hover:not(.mat-option-disabled), .unicorn-dark-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.unicorn-dark-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.unicorn-dark-theme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.unicorn-dark-theme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #607d8b;
}
.unicorn-dark-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.unicorn-dark-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}
.unicorn-dark-theme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-pseudo-checkbox::after {
  color: #303030;
}
.unicorn-dark-theme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.unicorn-dark-theme .mat-primary .mat-pseudo-checkbox-checked,
.unicorn-dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #607d8b;
}
.unicorn-dark-theme .mat-pseudo-checkbox-checked,
.unicorn-dark-theme .mat-pseudo-checkbox-indeterminate,
.unicorn-dark-theme .mat-accent .mat-pseudo-checkbox-checked,
.unicorn-dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.unicorn-dark-theme .mat-warn .mat-pseudo-checkbox-checked,
.unicorn-dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}
.unicorn-dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.unicorn-dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.unicorn-dark-theme .mat-app-background, .unicorn-dark-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
.unicorn-dark-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.unicorn-dark-theme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.unicorn-dark-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.unicorn-dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.unicorn-dark-theme .mat-badge-content {
  color: white;
  background: #607d8b;
}
.cdk-high-contrast-active .unicorn-dark-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.unicorn-dark-theme .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}
.unicorn-dark-theme .mat-badge {
  position: relative;
}
.unicorn-dark-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.unicorn-dark-theme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.unicorn-dark-theme .ng-animate-disabled .mat-badge-content,
.unicorn-dark-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.unicorn-dark-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.unicorn-dark-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.unicorn-dark-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.unicorn-dark-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.unicorn-dark-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.unicorn-dark-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.unicorn-dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.unicorn-dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.unicorn-dark-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.unicorn-dark-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.unicorn-dark-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.unicorn-dark-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.unicorn-dark-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.unicorn-dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.unicorn-dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.unicorn-dark-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.unicorn-dark-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.unicorn-dark-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.unicorn-dark-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.unicorn-dark-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.unicorn-dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.unicorn-dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .unicorn-dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.unicorn-dark-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.unicorn-dark-theme .mat-button, .unicorn-dark-theme .mat-icon-button, .unicorn-dark-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.unicorn-dark-theme .mat-button.mat-primary, .unicorn-dark-theme .mat-icon-button.mat-primary, .unicorn-dark-theme .mat-stroked-button.mat-primary {
  color: #607d8b;
}
.unicorn-dark-theme .mat-button.mat-accent, .unicorn-dark-theme .mat-icon-button.mat-accent, .unicorn-dark-theme .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.unicorn-dark-theme .mat-button.mat-warn, .unicorn-dark-theme .mat-icon-button.mat-warn, .unicorn-dark-theme .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.unicorn-dark-theme .mat-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-button.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-icon-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-icon-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-icon-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-stroked-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-stroked-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-stroked-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-button.mat-primary .mat-button-focus-overlay, .unicorn-dark-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .unicorn-dark-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-button.mat-accent .mat-button-focus-overlay, .unicorn-dark-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .unicorn-dark-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-button.mat-warn .mat-button-focus-overlay, .unicorn-dark-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .unicorn-dark-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .unicorn-dark-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .unicorn-dark-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.unicorn-dark-theme .mat-button .mat-ripple-element, .unicorn-dark-theme .mat-icon-button .mat-ripple-element, .unicorn-dark-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.unicorn-dark-theme .mat-button-focus-overlay {
  background: white;
}
.unicorn-dark-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-flat-button, .unicorn-dark-theme .mat-raised-button, .unicorn-dark-theme .mat-fab, .unicorn-dark-theme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.unicorn-dark-theme .mat-flat-button.mat-primary, .unicorn-dark-theme .mat-raised-button.mat-primary, .unicorn-dark-theme .mat-fab.mat-primary, .unicorn-dark-theme .mat-mini-fab.mat-primary {
  color: white;
}
.unicorn-dark-theme .mat-flat-button.mat-accent, .unicorn-dark-theme .mat-raised-button.mat-accent, .unicorn-dark-theme .mat-fab.mat-accent, .unicorn-dark-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-flat-button.mat-warn, .unicorn-dark-theme .mat-raised-button.mat-warn, .unicorn-dark-theme .mat-fab.mat-warn, .unicorn-dark-theme .mat-mini-fab.mat-warn {
  color: white;
}
.unicorn-dark-theme .mat-flat-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-flat-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-flat-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-flat-button.mat-primary, .unicorn-dark-theme .mat-raised-button.mat-primary, .unicorn-dark-theme .mat-fab.mat-primary, .unicorn-dark-theme .mat-mini-fab.mat-primary {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-flat-button.mat-accent, .unicorn-dark-theme .mat-raised-button.mat-accent, .unicorn-dark-theme .mat-fab.mat-accent, .unicorn-dark-theme .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-flat-button.mat-warn, .unicorn-dark-theme .mat-raised-button.mat-warn, .unicorn-dark-theme .mat-fab.mat-warn, .unicorn-dark-theme .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-flat-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-flat-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-flat-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-fab.mat-button-disabled.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-primary.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-accent.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-warn.mat-button-disabled, .unicorn-dark-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-flat-button.mat-primary .mat-ripple-element, .unicorn-dark-theme .mat-raised-button.mat-primary .mat-ripple-element, .unicorn-dark-theme .mat-fab.mat-primary .mat-ripple-element, .unicorn-dark-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.unicorn-dark-theme .mat-flat-button.mat-accent .mat-ripple-element, .unicorn-dark-theme .mat-raised-button.mat-accent .mat-ripple-element, .unicorn-dark-theme .mat-fab.mat-accent .mat-ripple-element, .unicorn-dark-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.unicorn-dark-theme .mat-flat-button.mat-warn .mat-ripple-element, .unicorn-dark-theme .mat-raised-button.mat-warn .mat-ripple-element, .unicorn-dark-theme .mat-fab.mat-warn .mat-ripple-element, .unicorn-dark-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.unicorn-dark-theme .mat-stroked-button:not([class*=mat-elevation-z]), .unicorn-dark-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-fab:not([class*=mat-elevation-z]), .unicorn-dark-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .unicorn-dark-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .unicorn-dark-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-button-toggle-standalone,
.unicorn-dark-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.unicorn-dark-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.unicorn-dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.unicorn-dark-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.unicorn-dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.unicorn-dark-theme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.unicorn-dark-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.unicorn-dark-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.unicorn-dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.unicorn-dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-card {
  background: #424242;
  color: white;
}
.unicorn-dark-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-checkbox-checkmark {
  fill: #303030;
}
.unicorn-dark-theme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.unicorn-dark-theme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.unicorn-dark-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .unicorn-dark-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .unicorn-dark-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .unicorn-dark-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .unicorn-dark-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.unicorn-dark-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.unicorn-dark-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.unicorn-dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.unicorn-dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #607d8b;
}
.unicorn-dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.unicorn-dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.unicorn-dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.unicorn-dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip::after {
  background: white;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #607d8b;
  color: white;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.unicorn-dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.unicorn-dark-theme .mat-table {
  background: #424242;
}
.unicorn-dark-theme .mat-table thead, .unicorn-dark-theme .mat-table tbody, .unicorn-dark-theme .mat-table tfoot,
.unicorn-dark-theme mat-header-row, .unicorn-dark-theme mat-row, .unicorn-dark-theme mat-footer-row,
.unicorn-dark-theme [mat-header-row], .unicorn-dark-theme [mat-row], .unicorn-dark-theme [mat-footer-row],
.unicorn-dark-theme .mat-table-sticky {
  background: inherit;
}
.unicorn-dark-theme mat-row, .unicorn-dark-theme mat-header-row, .unicorn-dark-theme mat-footer-row,
.unicorn-dark-theme th.mat-header-cell, .unicorn-dark-theme td.mat-cell, .unicorn-dark-theme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-cell, .unicorn-dark-theme .mat-footer-cell {
  color: white;
}
.unicorn-dark-theme .mat-calendar-arrow {
  border-top-color: white;
}
.unicorn-dark-theme .mat-datepicker-toggle,
.unicorn-dark-theme .mat-datepicker-content .mat-calendar-next-button,
.unicorn-dark-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.unicorn-dark-theme .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-calendar-body-cell-content,
.unicorn-dark-theme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.unicorn-dark-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.unicorn-dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-calendar-body-in-range::before {
  background: rgba(96, 125, 139, 0.2);
}
.unicorn-dark-theme .mat-calendar-body-comparison-identical,
.unicorn-dark-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.unicorn-dark-theme .mat-calendar-body-comparison-bridge-start::before,
.unicorn-dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.unicorn-dark-theme .mat-calendar-body-comparison-bridge-end::before,
.unicorn-dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.unicorn-dark-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.unicorn-dark-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.unicorn-dark-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.unicorn-dark-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.unicorn-dark-theme .mat-calendar-body-selected {
  background-color: #607d8b;
  color: white;
}
.unicorn-dark-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(96, 125, 139, 0.4);
}
.unicorn-dark-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.unicorn-dark-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.unicorn-dark-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.unicorn-dark-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(96, 125, 139, 0.3);
}
.unicorn-dark-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.unicorn-dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.unicorn-dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.unicorn-dark-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.unicorn-dark-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 215, 64, 0.3);
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.unicorn-dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.unicorn-dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.unicorn-dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.unicorn-dark-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.unicorn-dark-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
.unicorn-dark-theme .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-datepicker-toggle-active {
  color: #607d8b;
}
.unicorn-dark-theme .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.unicorn-dark-theme .mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}
.unicorn-dark-theme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.unicorn-dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.unicorn-dark-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .unicorn-dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .unicorn-dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .unicorn-dark-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.unicorn-dark-theme .mat-expansion-panel-header-title {
  color: white;
}
.unicorn-dark-theme .mat-expansion-panel-header-description,
.unicorn-dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.unicorn-dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.unicorn-dark-theme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #607d8b;
}
.unicorn-dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.unicorn-dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}
.unicorn-dark-theme .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.unicorn-dark-theme .mat-form-field-ripple {
  background-color: white;
}
.unicorn-dark-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #607d8b;
}
.unicorn-dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.unicorn-dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.unicorn-dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.unicorn-dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-error {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.unicorn-dark-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.unicorn-dark-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.unicorn-dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.unicorn-dark-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.unicorn-dark-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.unicorn-dark-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #607d8b;
}
.unicorn-dark-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.unicorn-dark-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.unicorn-dark-theme .mat-icon.mat-primary {
  color: #607d8b;
}
.unicorn-dark-theme .mat-icon.mat-accent {
  color: #ffd740;
}
.unicorn-dark-theme .mat-icon.mat-warn {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-input-element:disabled,
.unicorn-dark-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-input-element {
  caret-color: #607d8b;
}
.unicorn-dark-theme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.unicorn-dark-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.unicorn-dark-theme .mat-form-field.mat-warn .mat-input-element,
.unicorn-dark-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}
.unicorn-dark-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}
.unicorn-dark-theme .mat-list-base .mat-list-item {
  color: white;
}
.unicorn-dark-theme .mat-list-base .mat-list-option {
  color: white;
}
.unicorn-dark-theme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-list-item-disabled {
  background-color: black;
}
.unicorn-dark-theme .mat-list-option:hover, .unicorn-dark-theme .mat-list-option:focus,
.unicorn-dark-theme .mat-nav-list .mat-list-item:hover,
.unicorn-dark-theme .mat-nav-list .mat-list-item:focus,
.unicorn-dark-theme .mat-action-list .mat-list-item:hover,
.unicorn-dark-theme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.unicorn-dark-theme .mat-list-single-selected-option, .unicorn-dark-theme .mat-list-single-selected-option:hover, .unicorn-dark-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-menu-panel {
  background: #424242;
}
.unicorn-dark-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-menu-item {
  background: transparent;
  color: white;
}
.unicorn-dark-theme .mat-menu-item[disabled], .unicorn-dark-theme .mat-menu-item[disabled]::after,
.unicorn-dark-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-menu-item .mat-icon-no-color,
.unicorn-dark-theme .mat-menu-item-submenu-trigger::after {
  color: white;
}
.unicorn-dark-theme .mat-menu-item:hover:not([disabled]),
.unicorn-dark-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.unicorn-dark-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.unicorn-dark-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.unicorn-dark-theme .mat-paginator {
  background: #424242;
}
.unicorn-dark-theme .mat-paginator,
.unicorn-dark-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-paginator-decrement,
.unicorn-dark-theme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.unicorn-dark-theme .mat-paginator-first,
.unicorn-dark-theme .mat-paginator-last {
  border-top: 2px solid white;
}
.unicorn-dark-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.unicorn-dark-theme .mat-icon-button[disabled] .mat-paginator-increment,
.unicorn-dark-theme .mat-icon-button[disabled] .mat-paginator-first,
.unicorn-dark-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-progress-bar-background {
  fill: #cfd8dc;
}
.unicorn-dark-theme .mat-progress-bar-buffer {
  background-color: #cfd8dc;
}
.unicorn-dark-theme .mat-progress-bar-fill::after {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffe57f;
}
.unicorn-dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffe57f;
}
.unicorn-dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffccbc;
}
.unicorn-dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffccbc;
}
.unicorn-dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-progress-spinner circle, .unicorn-dark-theme .mat-spinner circle {
  stroke: #607d8b;
}
.unicorn-dark-theme .mat-progress-spinner.mat-accent circle, .unicorn-dark-theme .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.unicorn-dark-theme .mat-progress-spinner.mat-warn circle, .unicorn-dark-theme .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}
.unicorn-dark-theme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #607d8b;
}
.unicorn-dark-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.unicorn-dark-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .unicorn-dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .unicorn-dark-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.unicorn-dark-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.unicorn-dark-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .unicorn-dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .unicorn-dark-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.unicorn-dark-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.unicorn-dark-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .unicorn-dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .unicorn-dark-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.unicorn-dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.unicorn-dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.unicorn-dark-theme .mat-select-value {
  color: white;
}
.unicorn-dark-theme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-select-panel {
  background: #424242;
}
.unicorn-dark-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #607d8b;
}
.unicorn-dark-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.unicorn-dark-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.unicorn-dark-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.unicorn-dark-theme .mat-drawer {
  background-color: #424242;
  color: white;
}
.unicorn-dark-theme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.unicorn-dark-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.unicorn-dark-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.unicorn-dark-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.unicorn-dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.unicorn-dark-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(96, 125, 139, 0.54);
}
.unicorn-dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.unicorn-dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.unicorn-dark-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.unicorn-dark-theme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-primary .mat-slider-track-fill,
.unicorn-dark-theme .mat-primary .mat-slider-thumb,
.unicorn-dark-theme .mat-primary .mat-slider-thumb-label {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.unicorn-dark-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(96, 125, 139, 0.2);
}
.unicorn-dark-theme .mat-accent .mat-slider-track-fill,
.unicorn-dark-theme .mat-accent .mat-slider-thumb,
.unicorn-dark-theme .mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.unicorn-dark-theme .mat-warn .mat-slider-track-fill,
.unicorn-dark-theme .mat-warn .mat-slider-thumb,
.unicorn-dark-theme .mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.unicorn-dark-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}
.unicorn-dark-theme .mat-slider:hover .mat-slider-track-background,
.unicorn-dark-theme .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-slider-disabled .mat-slider-track-background,
.unicorn-dark-theme .mat-slider-disabled .mat-slider-track-fill,
.unicorn-dark-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.unicorn-dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.unicorn-dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.unicorn-dark-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.unicorn-dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .unicorn-dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .unicorn-dark-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.unicorn-dark-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.unicorn-dark-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.unicorn-dark-theme .mat-step-header.cdk-keyboard-focused, .unicorn-dark-theme .mat-step-header.cdk-program-focused, .unicorn-dark-theme .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .unicorn-dark-theme .mat-step-header:hover {
    background: none;
  }
}
.unicorn-dark-theme .mat-step-header .mat-step-label,
.unicorn-dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.unicorn-dark-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.unicorn-dark-theme .mat-step-header .mat-step-icon-selected,
.unicorn-dark-theme .mat-step-header .mat-step-icon-state-done,
.unicorn-dark-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #607d8b;
  color: white;
}
.unicorn-dark-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.unicorn-dark-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.unicorn-dark-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.unicorn-dark-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.unicorn-dark-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.unicorn-dark-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.unicorn-dark-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.unicorn-dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.unicorn-dark-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}
.unicorn-dark-theme .mat-stepper-horizontal, .unicorn-dark-theme .mat-stepper-vertical {
  background-color: #424242;
}
.unicorn-dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-horizontal-stepper-header::before,
.unicorn-dark-theme .mat-horizontal-stepper-header::after,
.unicorn-dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.unicorn-dark-theme .mat-tab-nav-bar,
.unicorn-dark-theme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.unicorn-dark-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.unicorn-dark-theme .mat-tab-label, .unicorn-dark-theme .mat-tab-link {
  color: white;
}
.unicorn-dark-theme .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.unicorn-dark-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.unicorn-dark-theme .mat-tab-group[class*=mat-background-] .mat-tab-header,
.unicorn-dark-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.unicorn-dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.unicorn-dark-theme .mat-tab-group.mat-primary .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .unicorn-dark-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.unicorn-dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.unicorn-dark-theme .mat-tab-group.mat-accent .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .unicorn-dark-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.unicorn-dark-theme .mat-tab-group.mat-warn .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .unicorn-dark-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .unicorn-dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #607d8b;
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.unicorn-dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffd740;
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.unicorn-dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.unicorn-dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.unicorn-dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.unicorn-dark-theme .mat-toolbar {
  background: #212121;
  color: white;
}
.unicorn-dark-theme .mat-toolbar.mat-primary {
  background: #607d8b;
  color: white;
}
.unicorn-dark-theme .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.unicorn-dark-theme .mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.unicorn-dark-theme .mat-toolbar .mat-form-field-underline,
.unicorn-dark-theme .mat-toolbar .mat-form-field-ripple,
.unicorn-dark-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.unicorn-dark-theme .mat-toolbar .mat-form-field-label,
.unicorn-dark-theme .mat-toolbar .mat-focused .mat-form-field-label,
.unicorn-dark-theme .mat-toolbar .mat-select-value,
.unicorn-dark-theme .mat-toolbar .mat-select-arrow,
.unicorn-dark-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.unicorn-dark-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.unicorn-dark-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.unicorn-dark-theme .mat-tree {
  background: #424242;
}
.unicorn-dark-theme .mat-tree-node,
.unicorn-dark-theme .mat-nested-tree-node {
  color: white;
}
.unicorn-dark-theme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.unicorn-dark-theme .mat-simple-snackbar-action {
  color: inherit;
}

.primary-color {
  color: #283593;
}

.primary-bg {
  background: #283593;
}

.accent-color {
  color: #ff5722;
}

.accent-bg {
  background: #ff5722;
}

body .mat-card .mat-card-image {
  max-width: inherit;
  border-bottom: 1px solid #eee;
}

.m-icon {
  position: absolute;
  top: -49px;
  right: -5px;
  transition: all 0.3s ease-in;
}

.wishlist-icon {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 99;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in 0s;
  text-align: center;
  line-height: 50px;
}
.wishlist-icon a {
  color: rgba(0, 0, 0, 0.4);
}
.wishlist-icon a:hover {
  color: rgba(0, 0, 0, 0.7);
}

mat-card:hover .m-icon {
  transform: translateY(-20px);
}

.no-card-content {
  padding-bottom: 0 !important;
}

.card-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.shop-card-gap {
  margin-top: -120px;
  padding: 1rem;
}

.section-title-toolbar {
  padding: 1rem;
}

.section-title-toolbar {
  border-radius: 10px;
}

.box-shadow-md:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.24) !important;
}
.box-shadow-md:hover .button-grey {
  background: #ff5722;
  color: #fff;
}

.wishlist-icon.wishlist-active a {
  color: black;
}

.simple-btn {
  background: transparent;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-content: center;
  align-items: center;
}

.button-lg {
  min-width: 160px !important;
  min-height: 50px;
}

.simple-btn .material-icons {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.button-grey {
  background-color: #E0E0E0;
  transition: all 0.3s ease-in-out 0s;
}
.button-grey:hover {
  background: #ff5722;
  color: #fff;
}

.transparent-btn {
  border: 2px solid #d8d8d8;
  display: block;
  padding: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  margin: 2.5rem 0;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  cursor: pointer;
}

html {
  font-size: 16px;
}

@media (max-width: 1366px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1280px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 14px;
  }
}
html,
body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  margin: 0;
  color: #3A405B;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  background: #f3f3f4;
}
html body .mat-card {
  font-family: "Roboto", sans-serif;
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  font-family: "Roboto", sans-serif;
}

[tabindex="-1"]:focus {
  outline: none;
}

body .mat-card {
  border-radius: 5px;
  overflow: hidden;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 0.875rem;
  color: #3A405B;
}

/* $typography
 ------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  color: inherit;
  font-weight: bold;
  margin-top: 0;
}

h1,
.h1 {
  font-size: 3.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

h2,
.h2 {
  font-size: 2.813rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

h3,
.h3 {
  font-size: 2.125rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

h4,
.h4 {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

h5,
.h5 {
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

h6,
.h6 {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
}

strong,
b {
  font-weight: 700;
}

.header-v1-top {
  background: rgba(255, 255, 255, 0.05);
  height: 100px !important;
}

.header-v1 .logo-site {
  text-align: center;
}

.header-v1-bottom {
  text-align: center;
  position: relative;
}

.header-v1-bottom-inner {
  position: relative;
}

.list-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px 16px;
}

.log-in-user .mat-button-wrapper {
  padding: 0 !important;
  width: 100%;
}
.log-in-user img {
  border-radius: 100%;
}

.user-tool button {
  margin: 0 5px;
}

menu {
  margin: 0;
}

.cart-menu-list {
  position: relative;
}
.cart-menu-list:hover .cart-menu-action {
  opacity: 1;
  visibility: visible;
}

.cart-menu-action {
  position: absolute;
  display: flex;
  background: #fff;
  right: 15px;
  transition: all 0.2s ease-in-out 0s;
  opacity: 0;
  border-radius: 5px;
  visibility: hidden;
}

.app-nav .app-nav-list {
  padding: 0;
}
.app-nav .app-nav-list > li {
  display: inline-block;
  margin: 0 10px;
  position: relative;
}
.app-nav .app-nav-list > li a {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 64px;
  padding: 0 15px;
  display: block;
  color: #fff;
}
.app-nav .app-nav-list > li:after {
  content: "";
  border-bottom: 5px;
  width: 0;
  transition: all 0.3s ease;
  height: 5px;
  left: 0;
  right: 0;
  background: #ff5722;
  display: block;
  position: absolute;
  bottom: 0;
}
.app-nav .app-nav-list > li:hover:after {
  width: 100%;
}
.app-nav .app-nav-list > li:last-child {
  background-color: #ff5722;
}
.app-nav .app-nav-list > li:last-child a {
  color: #fff !important;
}
.app-nav .app-nav-list li .nav-link.menu-item-has-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-nav .app-nav-list li .nav-link.menu-item-has-children:after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid #000;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transform: rotate(-90deg);
}

.search-box {
  position: absolute;
  bottom: -20px;
  right: 0;
}

.search-form {
  position: absolute;
  right: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
  top: 0;
  transition: all 0.3s ease;
  bottom: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
}
.search-form input {
  width: 100%;
  color: #3A405B;
  padding: 0 1rem;
  border: 0;
  font-size: 2rem;
  height: 64px;
}

.form-open .search-box {
  opacity: 0;
  visibility: hidden;
}
.form-open .search-form {
  opacity: 1;
  visibility: visible;
  overflow: hidden;
}

.close-btn {
  position: absolute !important;
  right: 0px;
  background: #fff !important;
  box-shadow: none !important;
  color: #3A405B !important;
}

body .mat-menu-panel {
  min-width: 300px;
  max-width: 300px;
}
body .mat-menu-panel i {
  vertical-align: middle;
}
body .mat-menu-panel span {
  display: inline-block;
}

.cart-menu-list {
  border-bottom: 1px solid #d5d5d5;
  padding: 1rem 0 !important;
}

.app-nav-list > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
}
.app-nav-list .sub-menu {
  position: absolute;
  width: 230px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in 0s;
  background: #fff;
  z-index: 99;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.app-nav-list .sub-menu .sub-menu {
  left: 100%;
  top: 0;
}
.app-nav-list .sub-menu li {
  display: block;
  line-height: normal;
  position: relative;
}
.app-nav-list .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  left: 100%;
}
.app-nav-list .sub-menu li a {
  color: #3A405B;
  text-align: left;
  line-height: normal;
  padding: 10px 20px;
}

.drop-wrap {
  display: block !important;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}

.logo-site {
  align-self: center;
}

.search-box {
  z-index: 9;
}

@media (max-width: 1279px) {
  .header-v1 .logo-site {
    text-align: left;
  }
}
@media (min-width: 960px) {
  .embryo-fixed-header .responsive-toggle {
    display: none;
  }
}
@media (max-width: 959px) {
  .embryo-fixed-header embryo-Menu {
    display: none;
  }
  .embryo-fixed-header .logo-site {
    width: 170px;
  }
}
@media (max-width: 959px) {
  .header-v1-bottom {
    display: none !important;
  }

  .header-v1-bottom-inner {
    position: static;
  }
  .header-v1-bottom-inner .search-box {
    bottom: 3px;
  }
}
@media (max-width: 767px) {
  .user-tool embryo-headercart,
.user-tool embryo-wishlist,
.user-tool .log-in-user {
    transform: scale(0.8);
  }
  .user-tool button {
    margin: 0;
  }

  body .mat-menu-panel {
    min-width: 210px;
    max-width: 210px;
  }
}
.mega {
  position: static !important;
}
.mega > .sub-menu {
  width: 1024px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 3rem;
}
.mega > .sub-menu > li {
  display: inline-block;
  width: 25%;
  vertical-align: top;
}
.mega > .sub-menu > li > a {
  color: #283593;
  font-size: 1.2rem;
  border-bottom: 1px solid #d5d5d5;
}
.mega .sub-menu .sub-menu {
  opacity: 1;
  visibility: inherit;
  position: static;
  display: inline-block;
  width: 100%;
  padding-left: 1.5rem;
  box-shadow: none;
}
.mega .sub-menu .sub-menu li:before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  background: #3A405B;
  height: 1px;
  width: 5px;
}
.mega .sub-menu .sub-menu a {
  text-transform: capitalize;
  cursor: pointer;
}

.list-flex .mat-form-field-appearance-legacy .mat-form-field-underline {
  background: transparent;
}
.list-flex .mat-form-field-infix {
  width: 80px;
  font-size: 14px;
}

.app-nav .app-nav-list > li.mega::after {
  content: none;
}

@media (max-width: 1199px) {
  .mega > .sub-menu {
    width: 700px;
  }
}
.header-v2 .logo-site {
  text-align: left;
}
.header-v2 .search-v2 input {
  background: rgba(255, 255, 255, 0.27);
  border: 0;
  width: 93%;
  height: 35px;
  border-radius: 5px;
  color: #fff;
  padding-left: 2.5rem;
}
.header-v2 .search-v2 input::-webkit-input-placeholder {
  color: #fff;
}
.header-v2 .search-v2 input:-moz-placeholder {
  color: #fff;
}
.header-v2 .search-v2 form {
  position: relative;
}
.header-v2 .search-v2 form:before {
  font-family: "Material Icons";
  content: "search";
  position: absolute;
  left: 10px;
  top: 2px;
}
.header-v2 .header-v2-bottom {
  background: #fff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.header-v2 .app-nav .app-nav-list > li a {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 64px;
  padding: 0 15px;
  display: block;
  color: #000;
}
.header-v2 .app-nav .sub-menu li a {
  line-height: 44px !important;
}
.header-v2 .app-nav .app-nav-list > li {
  margin: 0;
}
.header-v2 .list-flex {
  padding-right: 0;
}
.header-v2 .list-flex i {
  font-size: 20px;
  line-height: normal;
}
.header-v2 .list-flex .mat-mini-fab .mat-button-wrapper {
  padding: 0;
}
.header-v2 .list-flex .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
.header-v2 .list-flex .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.header-v2 .list-flex .mat-badge-medium .mat-badge-content {
  width: 15px;
  height: 15px;
  line-height: 14px;
  font-size: 10px;
}
.header-v2 .list-flex .mat-mini-fab {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.header-v2 .list-flex .log-in-user.mat-mini-fab {
  background: transparent;
}

.align-center {
  align-self: center;
}

.header-v2-top {
  height: 80px !important;
}

.header-v2-bottom-inner {
  align-self: center;
  display: flex;
}

.header-v3 .list-flex {
  padding-right: 0;
}
.header-v3 .list-flex i {
  font-size: 20px;
  line-height: normal;
}
.header-v3 .list-flex .mat-mini-fab .mat-button-wrapper {
  padding: 0;
}
.header-v3 .list-flex .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
.header-v3 .list-flex .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -4px;
}
.header-v3 .list-flex .mat-badge-medium .mat-badge-content {
  width: 15px;
  height: 15px;
  line-height: 14px;
  font-size: 10px;
}
.header-v3 .list-flex .mat-mini-fab {
  width: 25px;
  height: 25px;
  line-height: 25px;
}
.header-v3 .list-flex .mat-mini-fab {
  background: transparent;
}
.header-v3 .header-v3-bottom {
  height: 64px;
  background: #283593;
}
.header-v3 .header-v3-middle {
  height: 100px;
}
.header-v3 .header-v3-top .header-v3-top-tools .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0.25em;
}
.header-v3 form input {
  border: 1px solid #EEEEEE;
  background-color: #FFFFFF;
  border-radius: 5px;
  height: 47px;
  font-size: 17.36px;
  letter-spacing: 0.5px;
  padding-left: 14px;
  width: 100%;
}
.header-v3 form button.mat-fab {
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: none;
  width: auto;
  height: auto;
  margin-left: -56px;
}
.header-v3 .header-v3-top-tools > * {
  display: inline-block;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 10px;
  line-height: 33px;
}
.header-v3 .header-v3-top-tools > * .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}
.header-v3 .header-v3-top-tools .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0;
  background-color: transparent;
}
.header-v3 .header-v3-top-tools .mat-form-field-ripple,
.header-v3 .header-v3-top-tools .mat-toolbar .mat-form-field-underline {
  background-color: transparent;
}
.header-v3 .header-v3-top-tools .mat-form-field {
  width: 100px;
}
.header-v3 .header-v3-top {
  background: #000;
  color: #fff;
  font-size: 15px;
  height: 44px !important;
  font-weight: 400;
}

.grey-bg {
  background: #f1f1f1;
}

.embryo-fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out 0s;
  transform: translateY(-100%);
}
.embryo-fixed-header .logo-site {
  text-align: left;
}

.header-fixed .embryo-fixed-header {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.header-fixed .embryo-fixed-header .app-nav .app-nav-list > li > a {
  padding: 0 8px;
}

@media (max-width: 959px) {
  .header-v2-bottom.mat-toolbar-row {
    display: none;
  }

  .header-v3-bottom.mat-toolbar-row,
.header-v3-top.mat-toolbar-row {
    display: none;
  }

  .header-v3 .header-v3-middle {
    height: 70px;
  }

  .header-v3 .logo-site {
    text-align: center;
    width: 180px;
  }
}
@media (max-width: 991px) {
  .embryo-fixed-header .container {
    width: 100%;
  }
}
.app-side-nav-list {
  padding: 0;
}
.app-side-nav-list > li > a {
  padding: 1rem;
  display: block;
  font-size: 1rem;
}

@media (max-width: 960px) {
  .user-end .sidebar-area {
    width: 280px;
    background-image: url("/assets/images/bg-sidebar.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex !important;
    position: fixed !important;
    top: 0 !important;
    left: 0;
    bottom: 0 !important;
  }
  .user-end .sidebar-area embryo-sidebarmenu .mat-list-item {
    -webkit-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    -moz-transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    transition: 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    -webkit-transition-property: all;
    -moz-transition-property: all;
    transition-property: all;
  }
  .user-end .sidebar-area embryo-sidebarmenu a.mat-list-item {
    color: #fff;
  }
  .user-end .sidebar-area embryo-sidebarmenu .responsive-menu-side a {
    color: #fff;
  }
  .user-end .sidebar-area .mat-nav-list {
    width: 100%;
  }
  .user-end .sidebar-area .mat-nav-list .mat-icon {
    margin-right: 10px;
  }
  .user-end .sidebar-area .responsive-menu-side {
    padding-left: 20px;
  }

  headerone .logo-site {
    text-align: left;
  }

  .header-v1 .container {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .search-box {
    bottom: -8px;
  }

  .main-menu-wrap menu {
    display: none;
  }

  .list-flex {
    padding: 8px 0;
  }

  .search-box {
    transform: scale(0.8);
    z-index: 9;
  }
}
.cart-menu-action button {
  transform: scale(0.8);
}

.close-btn-sidebar {
  text-align: right;
  padding: 0 1rem;
}
.close-btn-sidebar a {
  display: inline-block;
  width: 35px;
  height: 35px;
  /* color: #fff; */
  background: #fff;
  text-align: center;
  line-height: 48px;
  border-radius: 3px;
}

.responsive-bar {
  background: #283593;
  height: 50px;
}
.responsive-bar .responsive-toggle.mat-icon-button {
  color: #fff;
  align-self: center;
}

@media (min-width: 960px) {
  .responsive-bar {
    display: none !important;
  }
}
@media (max-width: 575px) {
  .responsive-bar .container, .header-v2 .container {
    width: 100%;
  }
}
.arrow-left {
  top: 0;
  bottom: 0;
  left: -10px;
}

.arrow-right {
  top: 0;
  right: -10px;
  bottom: 0;
}

.arrow {
  margin: auto;
  position: absolute;
  border-radius: 100%;
  border: 0;
  transition: all 0.3s ease-in;
  width: 50px;
  cursor: pointer;
  height: 50px;
  box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.3);
}
.arrow:active, .arrow:focus {
  outline: none;
}

.ngucarousel .arrow {
  opacity: 0;
}
.ngucarousel:hover .arrow {
  opacity: 1;
}
.ngucarousel:hover .arrow-left {
  left: 0;
}
.ngucarousel:hover .arrow-right {
  right: 0;
}

.slider-img-1 {
  background-image: url("/assets/images/slider-1.jpg");
}

.slider-img-2 {
  background-image: url("/assets/images/slider-2.jpg");
}

.slider-img-3 {
  background-image: url("/assets/images/slider-3.jpg");
}

.ngx-slider-content {
  position: absolute;
  right: 0;
  width: 38%;
  top: 20%;
  padding-right: 2rem;
  text-align: center;
}
.ngx-slider-content h1 {
  font-weight: 900;
  font-style: italic;
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

.home-main-banner {
  margin-top: 4rem;
}

.aside-banner {
  margin-left: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
}
.aside-banner:last-child {
  margin-bottom: 0;
}

.bannerStyle {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
}

@media only screen and (min-width: 1366px) and (max-width: 1519px) {
  .bannerStyle {
    height: 470px;
  }
}
@media (max-width: 1366px) {
  .bannerStyle {
    height: 470px;
  }
}
@media (max-width: 1279px) {
  .aside-banner {
    margin-left: 0;
    margin-bottom: 0;
    display: inline-block;
    width: 48%;
    margin-top: 2rem;
    margin-right: 3%;
  }
  .aside-banner:last-child {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .ngx-slider-content h1 {
    font-size: 2rem;
  }
  .ngx-slider-content h2 {
    font-size: 1.5rem !important;
  }

  .bannerStyle {
    height: 300px;
  }
}
@media (max-width: 560px) {
  .bannerStyle {
    height: 200px;
  }

  .aside-banner {
    margin-left: 0;
    width: 100%;
    margin-top: 2rem;
    margin-right: 0;
  }
  .aside-banner:last-child {
    margin: 0;
    margin-top: 2rem;
  }

  .ngx-slider-content h1 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  .ngx-slider-content h2 {
    font-size: 1.2rem;
  }
  .ngx-slider-content button {
    transform: scale(0.8);
  }
}
embryo-BrandsLogo .item {
  text-align: center;
}
embryo-BrandsLogo .item img {
  background: #eee;
  padding: 1rem;
  opacity: 0.5;
  transition: all 0.3s ease-in-out 0s;
}
embryo-BrandsLogo .item a:hover img {
  opacity: 1;
}

embryo-homepageoneslider .slick-list {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

body .mat-card .mat-card-image {
  border-bottom: 1px solid #eee;
}

body .tile {
  box-shadow: none !important;
}

@media (max-width: 1199px) {
  .card-details .col-gap > div {
    padding-left: 1.5rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
.home-2-slider .slick-slide {
  margin: 3rem 1rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}
.home-2-slider .slick-slide:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.24);
}
.home-2-slider .home-2-slider-content {
  position: absolute;
  top: 23%;
  left: 5%;
}
.home-2-slider .text-main {
  font-size: 4.813rem;
  font-weight: 300;
  letter-spacing: -1.58px;
  line-height: 90px;
}
.home-2-slider .text-bold {
  font-size: 6.313rem;
  font-style: italic;
  font-weight: 900;
  line-height: 100px;
}
.home-2-slider .bold-sup {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 32px;
  font-style: normal;
}

@media (max-width: 1700px) {
  .home-2-slider .text-main {
    font-size: 2.813rem;
    line-height: 60px;
  }
  .home-2-slider .text-bold {
    font-size: 4.313rem;
    line-height: 70px;
  }
}
@media (max-width: 1550px) {
  .home-2-slider .home-2-slider-content {
    position: absolute;
    top: 15%;
  }
  .home-2-slider .text-main {
    font-size: 2rem;
    line-height: 36px;
  }
  .home-2-slider .text-bold {
    font-size: 4rem;
    line-height: 64px;
  }
}
.main-bg {
  height: 515px;
}

.overlay-section-content {
  position: relative;
  z-index: 9;
  padding: 2rem;
}
.overlay-section-content h4 {
  color: #fff;
}
.overlay-section-content button {
  letter-spacing: 1.25px;
  font-weight: bold;
}

.overlay-section-item {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.overlay-section-overlay {
  position: relative;
}
.overlay-section-overlay:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 7, 31, 0.55);
  pointer-events: none;
}

.end-left {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.end-right {
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: right;
}

.main-bg-inner {
  height: 241px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.feature-section-v2 mat-card {
  overflow: visible;
}

.feature-section-v2-list .material-icons {
  font-size: 2.3rem;
}
.feature-section-v2-list .mat-fab {
  width: 85px;
  height: 85px;
  margin-top: -65px;
}
.feature-section-v2-list p {
  color: rgba(0, 0, 0, 0.6);
}

.category-v2-list .category-v2-content {
  position: absolute;
  bottom: 0;
  padding: 1.5rem;
  z-index: 1;
}
.category-v2-list .category-v2-list-wrap {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.cta-image {
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
}

.cta-content {
  position: absolute;
  /* bottom: 0; */
  z-index: 9;
  left: 0;
  right: 0;
  top: 30%;
}
.cta-content h2 {
  font-size: 78px;
  font-weight: 300;
  line-height: 69px;
}
.cta-content h4 {
  font-size: 37px;
  line-height: 43px;
}
.cta-content i {
  font-size: 2.5rem;
}

.shadow-disable embryo-brandslogo .slick-list {
  box-shadow: none;
}

embryo-brandslogo .slick-list {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
embryo-brandslogo .slick-slide {
  margin: 3rem 1rem;
  border-radius: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}
embryo-brandslogo .slick-slide a {
  display: flex;
  justify-content: center;
}
embryo-brandslogo .slick-slide img {
  align-self: center;
}

.home-3-slider .slick-list {
  border-radius: 7px;
}

.slider-content-v3 {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  right: 0;
  bottom: 0;
  background: rgba(6, 7, 31, 0.59);
  padding-top: 10rem;
}
.slider-content-v3 .slider-content-v3-inner {
  width: 50%;
  margin: 0 auto;
}
.slider-content-v3 h4 {
  color: #FFFFFF;
  font-size: 96px;
  font-weight: 300 !important;
  letter-spacing: -1.5px;
  line-height: 92px;
  text-align: center;
}
.slider-content-v3 p {
  color: rgba(255, 255, 255, 0.87);
  font-size: 27px;
  font-weight: 300;
  line-height: 32px;
}

@media (max-width: 767px) {
  .home-2-slider .slick-slide {
    margin: 2rem 1rem;
  }
}
@media (max-width: 550px) {
  .home-2-slider .bold-sup {
    font-size: 1rem;
  }

  .home-2-slider .text-bold {
    font-size: 1.7rem;
    line-height: 20px;
  }
}
@media (max-width: 498px) {
  .home-2-slider .text-main {
    font-size: 1.2rem;
    line-height: 14px;
    letter-spacing: 0.5px;
  }
}
@media (max-width: 439px) {
  .home-2-slider .home-2-slider-content {
    top: 27%;
  }

  .home-2-slider .text-main, .home-2-slider .text-bold {
    display: none;
  }
}
.col-gap.category-v2-wrapper > embryo-productcategorycard {
  padding: 0 16px;
}

@media (max-width: 1519px) {
  .home-3-slider .slider-content-v3 {
    padding-top: 6rem;
  }

  .home-3-slider .slider-content-v3 h4 {
    font-size: 70px;
    line-height: 78px;
  }
}
@media (max-width: 1199px) {
  .home-3-slider .slider-content-v3 h4 {
    font-size: 50px;
    line-height: 58px;
  }

  .home-3-slider .slider-content-v3 h4 {
    font-size: 50px;
    line-height: 58px;
  }
}
@media (max-width: 991px) {
  .home-3-slider .slider-content-v3 {
    padding-top: 4rem;
  }

  .home-3-slider .slider-content-v3 h4 {
    font-size: 40px;
    line-height: 41px;
  }

  .tab-sldier-v2 .mat-tab-header {
    display: block;
  }
  .tab-sldier-v2 .mat-tab-label {
    min-width: auto;
    padding: 0 15px !important;
  }
  .tab-sldier-v2 .mat-ink-bar {
    height: 0;
  }
}
@media (max-width: 767px) {
  .slider-content-v3 p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  .home-3-slider .slider-content-v3 h4 {
    font-size: 30px;
    line-height: 32px;
  }

  .slider-content-v3 .slider-content-v3-inner {
    width: 90%;
  }
}
@media (max-width: 460px) {
  .home-3-slider .slider-content-v3 {
    padding-top: 1.5rem;
  }

  .slider-content-v3 p {
    display: none;
  }

  .home-3-slider .slider-content-v3 h4 {
    font-size: 21px;
    line-height: 21px;
    letter-spacing: 0;
  }
}
body .slick-dots li {
  margin: 0;
}
body .slick-dots li button::before {
  font-size: 10px !important;
}

.footer-v1 {
  background: #000000;
  color: #ffffff;
}
.footer-v1 * {
  color: #ffffff;
}

.shop-card-wrapper .slick-slide {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (min-width: 991px) {
  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .shop-card-wrapper .mat-tab-group {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .shop-card-wrapper .mat-tab-header-pagination {
    display: none !important;
  }
  .shop-card-wrapper .mat-tab-header {
    margin: 0 auto;
  }
  .shop-card-wrapper .mat-tab-nav-bar,
.shop-card-wrapper .mat-tab-header {
    border: 0;
  }
  .shop-card-wrapper .mat-tab-group.mat-primary .mat-ink-bar,
.shop-card-wrapper .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: none;
  }
  .shop-card-wrapper .mat-tab-label {
    height: 37px;
    padding: 0 14px;
    cursor: pointer;
    min-width: auto;
    margin: 0 5px;
    border-radius: 5px;
  }
  .shop-card-wrapper .mat-tab-label .mat-tab-label-content {
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .shop-card-wrapper .mat-tab-label-active.mat-tab-label {
    background: #fff;
    color: #000;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .shop-card-wrapper .mat-tab-list {
    margin-bottom: 3rem;
  }
}
@media (max-width: 990px) {
  .shop-card-wrapper .mat-tab-header-pagination {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .card-container .jp-card-container {
    width: 300px;
    margin: 0;
  }
  .card-container .jp-card {
    min-width: 285px;
  }
}
.cart-item-thumb {
  width: 100px;
  margin: 0 auto;
}

.cart-item {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.cart-item .mat-form-field {
  font-size: 1.5rem;
}
.cart-item .mat-form-field-infix * {
  color: #3A405B;
}
.cart-item .remove-cart i {
  font-size: 2rem;
}

@media (max-width: 1199px) {
  .cart-item-thumb {
    width: 50px;
    margin: 0 auto;
  }
}
@media (max-width: 959px) {
  .close-sm {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
}
.cart-product-image,
.cart-product-info > div {
  padding: 1rem;
}

@media (min-width: 768px) {
  .rating-header {
    display: flex;
    justify-content: space-between;
  }
  .rating-header > div {
    align-self: center;
  }
}
@media (max-width: 767px) {
  .add-later {
    display: none;
  }
}
.cdk-global-overlay-wrapper {
  overflow-y: auto;
}

.cart-menu-list div {
  word-wrap: break-word;
}

.mat-ex-wrapper .header-mat-tab {
  padding: 3rem 0 1rem 0;
}
.mat-ex-wrapper .container-mat-tab {
  padding: 4rem 0;
}
.mat-ex-wrapper .mat-expansion-panel-header {
  height: 70px !important;
  background: #283593;
}
.mat-ex-wrapper .mat-expansion-panel-header * {
  color: #fff;
}
.mat-ex-wrapper .mat-expansion-panel-header .mat-expansion-panel-header-description,
.mat-ex-wrapper .mat-expansion-panel-header .mat-expansion-indicator::after {
  color: #fff;
}
.mat-ex-wrapper .mat-expansion-panel-header.mat-expanded:focus,
.mat-ex-wrapper .mat-expansion-panel-header.mat-expanded:hover {
  background: #283593;
  color: #fff;
}
.mat-ex-wrapper .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-keyboard-focused,
.mat-ex-wrapper .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-program-focused,
.mat-ex-wrapper .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #283593;
}

.checkbox-small .mat-card {
  width: 150px;
}

.mat-card-pad-none {
  margin-left: -24px;
  margin-right: -24px;
}

mat-sidenav.em-sidebar {
  position: fixed;
}

.side-cart-head {
  text-align: center;
  background: #ff5722;
  padding: 2rem;
}
.side-cart-head * {
  color: #fff;
}
.side-cart-head i {
  font-size: 3rem;
}

.card-radio mat-radio-button {
  display: inline-block;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 1rem;
}

.checkout-option [ng-reflect-disabled=true],
.checkout-option [aria-disabled=true] {
  opacity: 0.7;
  cursor: not-allowed;
}

body .mat-drawer-backdrop {
  position: fixed !important;
}

.btn-group button {
  margin: 0.5rem;
}

@media (min-width: 560px) {
  .btn-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.about-info h2 {
  line-height: 1.1;
  font-size: 3.1rem;
  font-style: italic;
  font-size: bold;
}

.testimonail-v2 {
  background-image: url("/assets/images/about-bg.jpg");
}
.testimonail-v2 img {
  width: 130px;
  margin: 0 auto;
}
.testimonail-v2 .testi-content {
  font-size: 1.1rem;
}

.about-contact-bg {
  background-image: url("/assets/images/about-contact.jpg");
  padding: 10rem 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (min-width: 560px) {
  .about-cobtact-form-inner {
    padding: 3rem;
  }
}
.col-stretch {
  align-items: stretch !important;
}

.sign-in-image {
  background-image: url("/assets/images/login-img.jpg");
  background-size: cover;
  padding: 18rem 0;
}

.register-image {
  background-image: url("/assets/images/register.jpg");
  background-size: cover;
  padding: 18rem 0;
}

.thank-you-image {
  background-image: url("/assets/images/thank-you.jpg");
  background-size: cover;
  padding: 10rem 0;
  background-position: center center;
  border-radius: 5px;
}

.forgot-password-image {
  background-image: url("/assets/images/forgot-password.jpg");
  background-size: cover;
  padding: 18rem 0;
}

@media (min-width: 960px) {
  .thank-you-card {
    margin-top: -60px;
  }

  .form-margin {
    margin-left: -40px;
  }
}
@media (max-width: 959px) {
  .form-img {
    display: none;
  }
}
.cta-v1 {
  background-image: url("/assets/images/offers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.faq-page .mat-expansion-panel-header {
  height: auto !important;
  padding: 1rem;
}
.faq-page .mat-expansion-panel-header .mat-expansion-panel-header-title h5 {
  margin: 0;
}

.center-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.center-holder * {
  color: #fff;
}

.blog-content p {
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 2rem;
}

.dropcap {
  text-transform: uppercase;
  font-size: 110px;
  padding: 0 1rem 0 0;
  margin: -0.1rem 0 -1rem;
  float: left;
  font-weight: bold;
  line-height: 117px;
}

.center-holder i {
  margin-right: 0.5rem;
  vertical-align: top;
}

.blog-img-wrapper {
  height: calc(100vh - 164px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.blog-nav {
  background: #f3f3f4;
}

.round-radius {
  border-radius: 100%;
}

.commented-wrapper ul {
  list-style: none;
}

.commented-wrapper > ul {
  padding-left: 0;
}

.comment-item {
  border-bottom: 1px solid #eee;
  padding: 1rem;
}

.tag-n-social mat-chip-list {
  display: inline-block;
  margin: 0.3rem 0.2rem;
}
.tag-n-social embryo-socialshare button {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 599px) {
  .center-holder h2 {
    font-size: 1.5rem;
  }

  .comment-item .px-4 {
    padding: 0 !important;
  }
}
.post-wrapper .mat-card-content {
  padding-top: 1rem;
}

@media all and (-ms-high-contrast: none) {
  .center-holder {
    justify-content: flex-start;
  }
}
.contact-map agm-map {
  height: 400px;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-content * {
  color: #fff;
}

.overlay-wrap {
  position: relative;
}

.quick-shop .primary-rgba h2,
.quick-shop .pink-rgba h2 {
  font-weight: 900;
  font-size: 6rem;
  font-style: italic;
  line-height: 1;
}
.quick-shop .primary-rgba h5,
.quick-shop .pink-rgba h5 {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
}
.quick-shop .primary-rgba h3,
.quick-shop .pink-rgba h3 {
  font-weight: bolder;
  line-height: 1;
}
.quick-shop .black-rgba h6 {
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 500;
  line-break: 1;
}
.quick-shop .black-rgba h2 {
  font-size: 6rem;
  font-weight: 400;
  line-height: 1;
}
.quick-shop .black-rgba h5 {
  letter-spacing: 5px;
  text-transform: uppercase;
  line-height: 1;
}

@media (max-width: 991px) {
  .cta-content h2 {
    font-size: 38px;
    line-height: 39px;
  }

  .download-app-list:first-child {
    border: none !important;
  }
}
@media (max-width: 767px) {
  .cta-content h4 {
    display: none;
  }
}
@media (max-width: 478px) {
  .cta-content i {
    display: none;
  }

  .cta-content h2 {
    font-size: 21px;
    line-height: 23px;
  }
}
.subscribe-wrapper h3,
.subscribe-wrapper p,
.subscribe-wrapper .mat-form-field-label,
.subscribe-wrapper input,
.subscribe-wrapper .mat-form-field.mat-focused .mat-form-field-label,
.subscribe-wrapper .mat-icon-button {
  color: #fff;
}
.subscribe-wrapper .mat-form-field.mat-focused .mat-form-field-ripple,
.subscribe-wrapper .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #ffffff;
}

.subscribe-section-v2 .mat-form-field-wrapper *,
.subscribe-section-v2 .mat-form-field.mat-focused.mat-form-field-ripple {
  color: #fff;
}
.subscribe-section-v2 .mat-form-field-appearance-legacy .mat-form-field-underline,
.subscribe-section-v2 .mat-form-field.mat-focused .mat-form-field-ripple {
  background: #fff;
}

embryo-rating .br-default {
  margin: 0 !important;
}
embryo-rating .br-default .br-unit {
  margin-right: 0;
  font-size: 14px;
  height: 10px;
  width: 13px;
}
embryo-rating .br-default .br-selected::after {
  color: #EDB867 !important;
}
embryo-rating .br-default .br-unit::after {
  content: "★";
  color: #d2d2d2;
}

app-reviewpopup embryo-rating .br-default .br-unit {
  font-size: 34px;
  height: 40px;
  width: 35px;
}

html body ngx-loading-bar .ngx-spinner {
  background: #fff;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
html body ngx-loading-bar .ngx-spinner .ngx-spinner-icon {
  width: 50px !important;
  height: 50px !important;
  color: #283593;
}
html body ngx-loading-bar .ngx-bar {
  display: none !important;
}

body .img-zoom-container {
  position: relative !important;
}
body .img-zoom-container #myresult {
  display: none;
}

.offer-badge .mat-chip {
  top: 50px;
  position: absolute;
  padding: 0.5rem 0.7rem;
  left: 0;
}

.center-icon {
  background: #fff;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  text-align: center;
  line-height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  top: -50px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}
.center-icon i {
  font-size: 3rem;
  line-height: 100px;
}

.image-group img {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 7px;
}

.grid-card-v2 {
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.17);
}
.grid-card-v2 .custom-badge {
  padding: 0.2rem 0.6rem;
  border-radius: 5px;
  background-color: #DE3F3F;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.17);
  color: #fff;
  position: absolute;
  top: 15px;
}
.grid-card-v2 .price-tag-v2 {
  background: #fff;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.17);
  display: inline-block;
  padding: 0.2rem 0.78rem;
  margin: 0;
  font-weight: bold;
  border-radius: 5px;
  position: absolute;
  bottom: 1rem;
  line-height: normal;
}
.grid-card-v2 mat-card-footer.mat-card-footer {
  padding: 1rem;
  border-top: 1px solid #eee;
  margin-top: 1.5rem;
  color: rgba(0, 0, 0, 0.54);
}
.grid-card-v2 embryo-addtocardbutton button.mat-fab.mat-accent {
  background: #283593;
}
.grid-card-v2 embryo-rating .br-default .br-selected::after {
  color: #000 !important;
}
.grid-card-v2 .mat-upper {
  position: relative;
}
.grid-card-v2 .mat-card-content h5 {
  margin-bottom: 0;
  font-size: 17.36px;
  font-weight: bold;
}

.grid-card-wrapper .grid-toolbar {
  padding-top: 3.5rem;
  border-radius: 0;
}
.grid-card-wrapper .grid-toolbar .mat-toolbar-row {
  display: block;
}
.grid-card-wrapper .grid-toolbar .mat-toolbar-row .mat-tab-label-content {
  color: #fff;
  opacity: 1;
}
.grid-card-wrapper .grid-toolbar .mat-toolbar-row .mat-tab-label {
  opacity: 1;
  font-weight: 400;
  border-radius: 0;
}
.grid-card-wrapper .grid-toolbar .mat-toolbar-row .mat-tab-label-active.mat-tab-label {
  background: transparent;
  border-bottom: 1px solid #fff;
}
.grid-card-wrapper .grid-toolbar .mat-toolbar-row .mat-tab-group {
  float: left;
}
.grid-card-wrapper .mat-card {
  overflow: hidden;
}
.grid-card-wrapper .slick-slider {
  margin-top: -90px;
}
.grid-card-wrapper a.title-link {
  white-space: nowrap;
  width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.grid-card-v2 .mat-upper {
  position: relative;
  margin-top: -16px !important;
}

.download-app-list:first-child {
  border-right: 1px solid #000;
}

.download-app-list img {
  margin-top: -6px;
}

@media (max-width: 1560px) {
  body .image-group {
    padding-top: 1rem !important;
  }
}
@media (max-width: 1199px) {
  body .image-group {
    padding-top: 0rem !important;
  }

  .cta-content {
    top: 20%;
  }
}
@media (max-width: 991px) {
  .feature-section-v2-list .material-icons {
    font-size: 1.7rem;
  }

  .feature-section-v2-list {
    display: block !important;
    text-align: center;
    /* margin: 0 !important; */
  }

  .feature-section-v2-list .mat-fab {
    width: 55px;
    height: 55px;
    margin-top: 0;
    margin: 12px auto;
    text-align: center;
    display: block;
  }

  .ml-4.feature-section-v2-content {
    margin: 0 !important;
  }
}
@media (max-width: 560px) {
  .subscribe-section-v2 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .toolbar-title {
    display: block !important;
    padding-top: 2rem;
  }
}
.ais-SearchBox {
  overflow: hidden;
}
.ais-SearchBox button {
  display: none;
}
.ais-SearchBox input {
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #d5d5d5;
  padding: 10px;
  font-size: 1rem;
}

.ais-Panel-header,
.facet-category-title {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
}

.ais-RefinementList-checkbox {
  margin-right: 0.8rem;
}

.ais-RefinementList-label {
  margin-bottom: 1rem;
  display: block;
}

.ais-RangeSlider {
  margin-top: 70px;
  margin-bottom: 33px;
  padding: 0 1rem;
}
.ais-RangeSlider .noUi-connect {
  background: #ff5722;
}
.ais-RangeSlider .noUi-horizontal {
  height: 8px;
}
.ais-RangeSlider .noUi-handle::after, .ais-RangeSlider .noUi-handle::before {
  height: 7px;
  width: 2px;
  left: 13px;
  top: 6px;
}
.ais-RangeSlider .noUi-horizontal .noUi-handle {
  width: 22px;
  height: 18px;
}

.ais-RatingMenu-item {
  margin-bottom: 1rem;
}

body .ais-ClearRefinements-button {
  background: #ff5722;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
}

.ais-RatingMenu-count {
  margin: 0 0.3rem;
  background: #f3f3f4;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
}

.ais-Pagination-list li {
  display: inline-block;
  font-size: 1.3rem;
  border-radius: 3px;
}

.ais-Pagination {
  margin: 1rem 0;
}

.ais-Pagination-link {
  width: 33px;
  height: 33px;
  display: block;
  text-align: center;
  border-radius: 4px;
}

.ais-Pagination-item--selected {
  background: #ff5722;
  color: #fff;
}

.ais-RatingMenu-starIcon {
  fill: #ff5722;
}

.ais-RatingMenu-count {
  margin: 0 1rem;
}

body .ais-RatingMenu-starIcon {
  display: block;
  width: 25px;
  height: 25px;
}

.ais-HierarchicalMenu {
  padding-left: 1rem;
}

ul.ais-HierarchicalMenu-list > ais-hierarchical-menu-item {
  position: relative;
}
ul.ais-HierarchicalMenu-list > ais-hierarchical-menu-item:before {
  font-family: "Material Icons";
  content: "keyboard_arrow_right";
  position: absolute;
  top: 0;
  left: -15px;
}

ais-hierarchical-menu-item {
  margin-bottom: 1rem;
  display: block;
}
ais-hierarchical-menu-item .ais-HierarchicalMenu-link {
  font-weight: bold;
}
ais-hierarchical-menu-item .ais-HierarchicalMenu-list--child a {
  font-weight: normal;
}
ais-hierarchical-menu-item .ais-HierarchicalMenu-list--child {
  padding-top: 1rem;
  display: block;
}

.ais-HierarchicalMenu-count, .ais-RefinementList-count, .ais-RatingMenu-count {
  background: rgba(0, 0, 0, 0.4);
  padding: 0.2rem 0.3rem;
  color: #fff;
  margin: 0.3rem;
  border-radius: 3px;
  font-size: 0.8rem;
}

ais-stats {
  text-align: right;
  font-weight: bold;
}

.algolia-main-list .ais-SortBy select, .algolia-main-list ais-hits-per-page select {
  width: 150px;
  border: 1px solid #eee;
  height: 40px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.algolia-main-list ais-panel, .algolia-main-list ais-hits-per-page {
  display: inline-block;
  margin-right: 0.5rem;
}

.counter-item {
  display: inline-block;
  margin-right: 1.2rem;
  margin-bottom: 1.5rem;
}
.counter-item .counter-digit {
  font-size: 3.25rem;
}
.counter-item .counter-text {
  font-size: 1.25rem;
  display: inline-block;
  margin: 0 0.5rem;
}

@media (max-width: 560px) {
  .counter-item {
    display: inline-block;
    margin-right: 0.5rem;
  }
  .counter-item .counter-digit {
    font-size: 2rem;
  }
  .counter-item .counter-text {
    font-size: 1rem;
    display: inline-block;
    margin: 0 0.2rem;
  }
}
.user-nav-list {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.user-nav-list .mat-list-item-content a i {
  vertical-align: top;
  width: 40px;
  text-align: center;
}

.proflie-field span {
  display: inline-block;
  width: calc(100% - 180px);
}

.proflie-field label {
  width: 150px;
  display: inline-block;
}

.login-user-img img {
  border-radius: 100%;
}

.edit-profile {
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.2;
}

.final-receipt-page .account-info .reason-wrap .mat-form-field {
  line-height: 0.4rem;
}

@media (max-width: 991px) {
  .account-info .user-info {
    margin-bottom: 1rem;
  }
}
*, ::after, ::before {
  box-sizing: border-box;
}

code {
  padding: 8px;
  background: #f3f3f4;
}

pre {
  background-color: #f3f3f4;
  padding: 15px;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.table thead {
  text-align: left;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #eceeef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-responsive-x {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive-x .table-scroll-wrap {
  min-width: 900px;
}

.table-responsive-y {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.text-nowrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
@media (min-width: 1520px) {
  .container {
    width: 1450px;
  }
}
.col-gap {
  margin-left: -15px;
  margin-right: -15px;
}
.col-gap > div {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.block-title h2 {
  margin-bottom: 3rem;
}

.section-gap {
  padding: 5rem 0;
}

.section-gap-lg {
  padding: 7rem 0;
}

.relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

hr {
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 3rem 0;
}

.dark-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.spacer {
  border-bottom: 1px solid #d5d5d5;
  margin: 1.5rem 0;
}

.bg-grey {
  background: #f3f3f4;
}

.round-corner {
  border-radius: 100%;
}

.page-title-bar {
  padding: 7rem 0;
  text-align: center;
  background-image: url("/assets/images/page-title-bar.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
}
.page-title-bar * {
  color: #fff;
}

.position-relative {
  position: relative;
}

.shop-card-wrapper .mat-card {
  margin-bottom: 2rem;
  overflow: hidden;
}

@media (max-width: 959px) {
  .product-detail-content {
    margin-top: 3rem;
  }
}
@media (max-width: 420px) {
  .detail-btns button {
    margin-bottom: 1rem;
    display: block;
  }
}
div.mat-select-panel {
  max-height: 280px;
}

.user-list-wrap {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
}

.alt-sec:nth-of-type(2n) > div:first-child {
  order: 1;
}

embryo-team {
  width: 100% !important;
}

body app-main, body .mat-drawer-content {
  overflow-x: hidden;
  overflow-y: auto;
}

.user-switch-btn {
  position: fixed;
  right: 0;
  top: calc(50% - 4rem);
  z-index: 999;
}
.user-switch-btn button {
  height: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.h-100 {
  height: 100%;
}

.over-visible {
  overflow: visible !important;
}

.d-inline {
  display: inline !important;
}

.d-block {
  display: block !important;
}

.text-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline;
}

.text-xs {
  font-size: 50% !important;
}

.text-sm {
  font-size: 70% !important;
}

.text-md {
  font-size: 80% !important;
}

.text-lg {
  font-size: 110% !important;
}

.text-xl {
  font-size: 120% !important;
}

.text-xxl {
  font-size: 140% !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.text-inverse {
  color: #fff;
}

.text-muted {
  color: rgba(0, 0, 0, 0.38);
}

.text-italic {
  font-style: italic !important;
}

.list-sm.mat-list .mat-list-item {
  height: 28px;
  font-size: 0.875rem;
}

.list-sm.mat-list .mat-list-item .mat-list-item-content {
  padding: 0;
}

.no-style {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}
.no-style li {
  margin-bottom: 0.4rem;
}

.bullets-list {
  padding-left: 0;
  list-style: none;
}
.bullets-list li {
  position: relative;
  padding-left: 0.8rem;
  margin-bottom: 0.3rem;
}
.bullets-list li:before {
  content: "";
  width: 5px;
  height: 5px;
  display: inline-block;
  background: #3A405B;
  border-radius: 100%;
  position: absolute;
  top: 46%;
  left: 2px;
}

.primary-rgba {
  background: rgba(40, 53, 147, 0.75);
}

.black-rgba {
  background: rgba(0, 0, 0, 0.69);
}

.pink-rgba {
  background: rgba(183, 58, 174, 0.75);
}

.accent-rgba {
  background: rgba(255, 87, 34, 0.75);
}

.bg-brown {
  background-color: #212121;
}

.bg-white {
  background: #fff;
}

/*=============|Sidebar|============*/
.admin-panel, .admin-panel body {
  height: 100%;
}

.mat-drawer-transition .mat-drawer-content {
  transition: margin 0.4s ease 0s !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .admin-panel body {
    /* IE11 */
  }
  .admin-panel body mat-sidenav .sidebar-panel .sub-menu, .admin-panel body .mat-list-item .menu-caret {
    transition: 0 !important;
  }
}
.admin-panel .mat-card {
  margin: 0.875rem;
}
.admin-panel .table th, .admin-panel .table td {
  vertical-align: middle;
}
.admin-panel body .sidebar-logo.mat-toolbar {
  align-items: center;
  background: transparent none repeat scroll 0 0;
  justify-content: center;
  padding: 0 6px;
}
.admin-panel body .app {
  height: 100%;
  overflow: hidden;
}
.admin-panel body .mat-sidenav {
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 4rem;
}
.admin-panel body .embryo-logo {
  padding: 2rem;
  text-align: center;
}
.admin-panel body .embryo-logo .logo-text {
  margin: 0 1rem;
}
.admin-panel body mat-sidenav .sidebar-panel .mat-nav-list a {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.25rem;
  padding: 0 1.5rem;
  font-weight: 400;
}
.admin-panel body mat-sidenav .sidebar-panel .mat-nav-list a {
  color: #fff;
  outline: none;
}
.admin-panel body mat-sidenav .sidebar-panel .sub-menu {
  padding-top: 0;
  overflow: hidden;
  transition: all 0.4s ease 0s;
  transition-property: max-height;
  max-height: 0;
}
.admin-panel body mat-sidenav .sidebar-panel .sub-menu a {
  padding-left: 4rem;
  margin: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.admin-panel body mat-sidenav .sidebar-panel .open .sub-menu {
  max-height: 1000px;
}
.admin-panel body .embryo-container-wrap {
  height: 100%;
}
.admin-panel body .mat-sidenav-content {
  height: 100%;
  overflow: hidden;
}
.admin-panel body mat-sidenav .sidebar-panel .navigation mat-icon:not(.caret) {
  margin-right: 0.75rem;
}
.admin-panel body .sidebar-panel {
  width: 200px;
  height: 100%;
}
.admin-panel body .sidebar-container .mat-nav-list .mat-list-item .mat-list-item-content {
  height: auto;
  display: block;
  margin-bottom: 0;
  padding: 0;
}
.admin-panel body .mat-nav-list .mat-list-item-content:hover,
.admin-panel body .mat-nav-list .mat-list-item-content.mat-list-item-focus,
.admin-panel body .active-link {
  background-color: rgba(14, 27, 121, 0.7);
}
.admin-panel body .sidebar-panel .material-icons {
  font-size: 1.4rem;
}
.admin-panel body .mat-list-item .menu-caret {
  transition: all 0.4s ease 0s;
  margin-right: 0 !important;
}
.admin-panel body .mat-list-item.open .menu-caret {
  transform: rotate(90deg);
}
.admin-panel body .embryo-user-thumb {
  padding: 0.9375rem 0;
}
.admin-panel body .embryo-header-toolbar {
  box-shadow: -2px -42px 34px 21px #283593;
}
.admin-panel body .embryo-header-toolbar .secondary-menu {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.admin-panel body .admin-panel-header .list-flex {
  padding: 0px;
}
.admin-panel body .admin-panel-header .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1em;
}
.admin-panel body .admin-panel-header .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.9375em 0;
}
.admin-panel body .secondary-menu button {
  height: 50px;
  margin: 0 0.3rem;
  min-width: 50px;
  width: 50px;
  padding: 0;
}
.admin-panel body .mat-menu-content .mat-menu-item {
  font-size: 14px !important;
  height: 38px !important;
  line-height: 38px !important;
}
.admin-panel .embryo-base-container {
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.admin-panel .embryo-container {
  padding: 2rem 0.5rem 0.5rem 0.5rem;
}
.admin-panel .footer-wrap {
  margin-bottom: -0.5rem;
  align-self: flex-end;
}
.admin-panel .sidebar-area.mat-sidenav {
  background-color: #283593;
  height: 100%;
}
.admin-panel .sidebar-container {
  height: calc(100% - 64px);
}
.admin-panel .mat-sidenav-container .mat-nav-list .mat-list-item {
  height: auto;
}
.admin-panel body .mat-form-field-appearance-legacy .mat-form-field-label,
.admin-panel body .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.38) !important;
}
.admin-panel body .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.12);
}
.admin-panel body .mat-form-field-appearance-legacy .mat-form-field-infix {
  font-size: 0.875rem;
}
.admin-panel body .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #283593;
}
.admin-panel body .mat-mini-fab .mat-button-wrapper {
  display: inline;
}
.admin-panel .embryo-img-table {
  padding-top: 2rem !important;
}
@media (max-width: 1525px) {
  .admin-panel .embryo-mail-wrapper .mat-list .mat-list-item .mat-list-item-content,
.admin-panel .embryo-mail-wrapper .mat-nav-list .mat-list-item .mat-list-item-content {
    height: 30px;
  }
}
@media (max-width: 1279px) {
  .admin-panel .boxed-layout-md {
    display: none;
  }
  .admin-panel .embryo-breadcrumbs {
    display: none;
  }
}
@media (max-width: 959px) {
  .admin-panel .embryo-header-toolbar .search-bar,
.admin-panel .embryo-header-toolbar .mat-icon-button {
    display: none;
  }
}
@media (max-width: 959px) {
  .admin-panel body .mat-drawer-container {
    -webkit-overflow-scrolling: auto !important;
  }
  .admin-panel body .sidebar-container {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.admin-panel .sidebar-label {
  position: absolute;
  background: red;
  color: #fff;
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.6rem;
  top: 31%;
  right: 45px;
  letter-spacing: 1px;
}
@media (max-width: 599px) {
  .admin-panel body .secondary-menu button {
    min-width: 34px;
    width: 34px;
  }
  .admin-panel body .secondary-menu button .material-icons {
    font-size: 22px;
  }
  .admin-panel .embryo-header-toolbar.mat-toolbar-row, .admin-panel .embryo-header-toolbar.mat-toolbar-single-row {
    height: auto;
    min-height: 56px;
  }
  .admin-panel .embryo-header-toolbar.mat-toolbar-row .navbar-brand, .admin-panel .embryo-header-toolbar.mat-toolbar-single-row .navbar-brand {
    max-width: 125px;
    white-space: normal;
  }
}

.chart-shadow canvas {
  -webkit-filter: drop-shadow(0 8px 8px #A9A9A9);
  filter: drop-shadow(0 8px 8px #A9A9A9);
}

.card-spinner.reload {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
}

.e-commerce-wrapper svg {
  width: 100% !important;
  height: 100% !important;
}
.e-commerce-wrapper .ngx-charts {
  float: none !important;
  overflow: visible;
}

.dash-title {
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.Todo-list-wrapper .app-card .list .delete-btn {
  opacity: 0;
}
.Todo-list-wrapper .app-card .list .input-slot {
  min-width: 40px;
}
.Todo-list-wrapper .app-card .list:hover {
  background-color: #dfeaff !important;
}
.Todo-list-wrapper .app-card .list:hover .delete-btn {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.top-selling-wrapper .cart-layout .number-chart {
  width: 30%;
  height: 130px;
}
.top-selling-wrapper .slick-prev:before, .top-selling-wrapper .slick-next:before {
  display: none;
}

.sales-stat-chart canvas {
  height: 450px;
}

.add-list-form {
  width: 450px;
}
.add-list-form .mat-dialog-actions {
  margin-bottom: 0;
}

.a-badge.primary-bg, .a-badge.accent-bg {
  color: #fff;
}

.chat-wrapper {
  margin: -31px 0 1rem;
}
.chat-wrapper .primary-bg h1, .chat-wrapper .primary-bg h2, .chat-wrapper .primary-bg h3, .chat-wrapper .primary-bg h4, .chat-wrapper .primary-bg h5, .chat-wrapper .primary-bg h6, .chat-wrapper .primary-bg p, .chat-wrapper .primary-bg a, .chat-wrapper .primary-bg span {
  color: #fff;
}
.chat-wrapper .chatsidebar-wrapper {
  width: 15.5rem;
}
.chat-wrapper .chatsidebar-wrapper .a-list {
  transition: all 0.3s ease;
  cursor: pointer;
}
.chat-wrapper .chatsidebar-wrapper .a-list:hover {
  background-color: rgba(40, 53, 147, 0.7);
}
.chat-wrapper .chatsidebar-wrapper .a-list:hover h1, .chat-wrapper .chatsidebar-wrapper .a-list:hover h2, .chat-wrapper .chatsidebar-wrapper .a-list:hover h3, .chat-wrapper .chatsidebar-wrapper .a-list:hover h4, .chat-wrapper .chatsidebar-wrapper .a-list:hover h5, .chat-wrapper .chatsidebar-wrapper .a-list:hover h6, .chat-wrapper .chatsidebar-wrapper .a-list:hover p, .chat-wrapper .chatsidebar-wrapper .a-list:hover a, .chat-wrapper .chatsidebar-wrapper .a-list:hover span {
  color: #fff;
}
.chat-wrapper .chatsidebar-wrapper .a-toolbar {
  height: 64px !important;
}
.chat-wrapper .chatsidebar-wrapper .a-list-img {
  min-width: 56px;
}
.chat-wrapper .chatsidebar-wrapper .scroll-bar {
  height: calc(100vh - 129px) !important;
}
.chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text {
  background-color: rgba(40, 53, 147, 0.7);
}
.chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title h1, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title h2, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title h3, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title h4, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title h5, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title h6, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title p, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title a, .chat-wrapper .chatsidebar-wrapper .chatsidebar-content .chat-active-text .list-title span {
  color: #fff;
}
.chat-wrapper .chat-footer {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  padding: 0 1rem;
  height: 90px;
  background-color: #fff;
}
.chat-wrapper .chat-footer .input-slot label {
  line-height: normal;
}
.chat-wrapper .sidenav-wrapper {
  height: calc(100vh - 81px);
  overflow: hidden;
}
.chat-wrapper .sidenav-wrapper .mat-drawer-inner-container {
  overflow: hidden;
}
.chat-wrapper .chatarea-wrapper .chatarea-content .a-toolbar-content {
  background-color: #fff;
  height: 64px !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
}
.chat-wrapper .chatarea-wrapper .chatarea-content .toggle-btn {
  display: none;
}
.chat-wrapper .chatarea-wrapper .chatarea-content .chat-body .scroll-bar {
  height: calc(100vh - 235px) !important;
}
.chat-wrapper .chatarea-wrapper .chatarea-content .chat-body .chat-block .chat-bubble-wrap {
  margin: 0 1rem;
  width: calc(50% - 70px);
}
.chat-wrapper .chatarea-wrapper .chatarea-content .chat-body .chat-block .chat-bubble-wrap .chat-bubble.odd {
  border-radius: 20px 20px 0 20px;
}
.chat-wrapper .chatarea-wrapper .chatarea-content .chat-body .chat-block .chat-bubble-wrap .chat-bubble.even {
  border-radius: 20px 20px 20px 0;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  background-color: #ff5722;
}

.avatar-wrap {
  width: 50px;
}
.avatar-wrap img {
  width: 50px;
  height: 50px;
}

@media (max-width: 959px) {
  .chatarea-content .toggle-btn {
    display: block !important;
  }
}
@media (max-width: 599px) {
  .chat-wrapper .chatarea-wrapper .chatarea-content .chat-body .chat-block .chat-bubble-wrap {
    margin: 0 1rem;
    width: calc(100% - 70px);
  }

  .chat-wrapper .chatarea-content .chat-head-right .video-icon {
    display: none;
  }
}
.trade-chart-wrap canvas {
  height: 450px !important;
}

.table-tab-list {
  min-height: 350px;
}

.product-icon .active {
  opacity: 0.4;
}

.invoice-popup {
  height: 85vh;
  max-width: 680px;
  max-width: 680px;
  overflow: auto;
}

.final-receipt-page .account-info .mat-list-item .mat-list-item-content {
  padding-left: 0px;
  padding-right: 0px;
}

.orderlist-popup {
  width: 320px;
  max-width: 100%;
}

.edit-icon, .add-icon {
  position: relative;
}
.edit-icon::after, .add-icon::after {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  content: "";
}
.edit-icon:hover::after, .add-icon:hover::after {
  opacity: 1;
  visibility: visible;
}

.edit-icon .edit-btn, .add-icon .add-btn {
  position: absolute;
  top: calc(50% - .6rem);
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 0;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 0;
  z-index: 3;
}
.edit-icon .edit-btn .mat-icon, .add-icon .add-btn .mat-icon {
  color: #283593;
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.edit-icon .edit-btn label, .add-icon .add-btn label {
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}

.edit-text, .add-text {
  position: relative;
}
.edit-text::after, .add-text::after {
  font-feature-settings: "liga";
  font-family: "Material Icons";
  content: "edit";
  -webkit-font-feature-settings: "liga";
  position: absolute;
  top: 2px;
  left: -25px;
  font-size: 0.8em;
  color: #283593;
}
.edit-text .mat-form-field-underline, .add-text .mat-form-field-underline {
  bottom: -8px;
}

.add-text::after {
  content: "add";
}

.edit-check .mat-form-field-wrapper, .edit-check .mat-form-field-infix, .add-check .mat-form-field-wrapper, .add-check .mat-form-field-infix {
  padding: 0;
  border: 0;
}

@media (max-width: 959px) {
  .edit-text {
    padding-left: 0.8rem;
  }
  .edit-text::after {
    left: -12px;
    font-size: 0.8em;
  }

  .add-text::after {
    left: -20px;
  }

  .final-receipt-page .user-content-wrapper {
    padding: 0px;
  }
  .final-receipt-page .user-content-wrapper .mat-card {
    margin: 0px;
  }
}
@media (max-width: 991px) {
  .edit-icon .edit-btn .mat-icon {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }

  .trade-chart-wrap canvas {
    height: 250px !important;
  }
}
.rtl-layout a {
  position: fixed;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #283593;
  z-index: 99;
  color: #fff;
  top: 50%;
  display: block;
  text-align: center;
  border-radius: 5px 0 0 5px;
  border: 2px solid #fff;
  border-right: 0;
}

@media (max-width: 1279px) {
  [dir=rtl] .logo-site {
    text-align: right;
  }
}
.admin-panel body [dir=rtl] mat-sidenav .sidebar-panel .sub-menu a {
  padding-left: 1.5rem !important;
  padding-right: 4rem !important;
}
.admin-panel body [dir=rtl] .mat-list-item .menu-caret {
  transform: rotate(180deg);
}
.admin-panel body [dir=rtl] .mat-list-item.open .menu-caret {
  transform: rotate(90deg);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .admin-panel body [dir=rtl] {
    /* IE11 */
  }
  .admin-panel body [dir=rtl] .mat-list-item .menu-caret {
    transform: rotate(180deg);
  }
  .admin-panel body [dir=rtl] .mat-list-item.open .menu-caret {
    transform: rotate(270deg) !important;
  }
}
@supports (-ms-ime-align: auto) {
  .admin-panel body [dir=rtl] .mat-list-item .menu-caret {
    transform: rotate(180deg);
  }
  .admin-panel body [dir=rtl] .mat-list-item.open .menu-caret {
    transform: rotate(270deg) !important;
  }
}
[dir=rtl] .cart-menu-action {
  left: 15px;
  right: auto;
}
[dir=rtl] .rtl-layout a {
  border-left: 0;
  border-right: 2px solid #fff;
  right: auto;
  left: 0;
  border-radius: 0px 5px 5px 0px;
}
[dir=rtl] .download-app-list:first-child {
  border-left: 1px solid #000;
  border-right: 0;
}
[dir=rtl] ais-stats {
  text-align: left;
}
[dir=rtl] ais-panel, [dir=rtl] ais-hits-per-page {
  margin-left: 0.5rem;
  margin-right: 0rem;
}
[dir=rtl] .header-v2 .search-v2 input {
  padding-right: 2.5rem;
  padding-left: 0;
}
[dir=rtl] .header-v2 .search-v2 form::before {
  right: 10px;
  left: auto;
}
[dir=rtl] .header-v2 .logo-site {
  text-align: right;
}
[dir=rtl] .aside-banner {
  margin-right: 2rem;
  margin-left: 0;
}
[dir=rtl] .wishlist-icon {
  right: auto;
  left: 10px;
}
[dir=rtl] .header-v3 form input {
  padding-right: 14px;
  padding-left: 0;
}
[dir=rtl] .header-v3 form button.mat-fab {
  margin-right: -56px;
  margin-left: 0px;
}
[dir=rtl] .header-v3 form button.mat-fab .header-v3 .header-v3-top-tools > * {
  display: inline-block;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 10px;
  line-height: 44px;
}
[dir=rtl] .embryo-fixed-header .logo-site, [dir=rtl] .header-v3 .logo-site {
  text-align: right;
}
[dir=rtl] .embryo-fixed-header .logo-site .header-v3 .header-v3-top-tools > *, [dir=rtl] .header-v3 .logo-site .header-v3 .header-v3-top-tools > * {
  display: inline-block;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 10px;
  line-height: 44px;
}
[dir=rtl] .header-v3 .header-v3-top-tools > * {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
[dir=rtl] .grid-card-wrapper .grid-toolbar .mat-toolbar-row .mat-tab-group {
  float: right;
}
[dir=rtl] .ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em;
  margin-right: 1em;
}
[dir=rtl] ul.ais-HierarchicalMenu-list > ais-hierarchical-menu-item::before {
  left: auto;
  right: -15px;
}
[dir=rtl] .sidebar-area {
  left: auto;
  right: 0;
}
[dir=rtl] .close-btn-sidebar {
  text-align: left;
}
[dir=rtl] .sidebar-area .mat-nav-list .mat-icon {
  margin-right: 0px;
  margin-left: 10px;
}
[dir=rtl] .app-nav-list .sub-menu li a {
  text-align: right;
}
[dir=rtl] .mega .sub-menu .sub-menu {
  padding-left: 0;
  padding-right: 1.5rem;
}
[dir=rtl] .mega .sub-menu .sub-menu li::before {
  left: auto;
  right: 0;
}
[dir=rtl] .feature-list > div {
  border-left: 2px solid #eee;
  border-right: 0;
}
[dir=rtl] .feature-list > div:first-child {
  border: none;
}
[dir=rtl] .edit-text::after, [dir=rtl] .add-text::after {
  left: auto;
  right: -25px;
}
[dir=rtl] .user-switch-btn {
  right: auto;
  left: 0;
}
[dir=rtl] .user-switch-btn button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
[dir=rtl] .close-btn {
  left: 0px;
  right: auto;
}
[dir=rtl] .search-box {
  bottom: -20px;
  right: auto;
  left: 0;
}
[dir=rtl] .user-content {
  padding-top: 0.6rem;
  padding-right: 2rem;
  padding-left: 0;
}
[dir=rtl] .m-icon {
  position: absolute;
  top: -49px;
  left: -5px;
  right: auto;
  transition: all 0.3s ease-in;
}
[dir=rtl] .dropcap {
  padding: 0 0rem 0 1rem;
  margin: -0.1rem 0 -1rem;
  float: right;
}
[dir=rtl] .bullets-list li::before {
  left: auto;
  right: -15px;
}
[dir=rtl] .mr-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
[dir=rtl] .ml-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
[dir=rtl] .mr-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}
[dir=rtl] .ml-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}
[dir=rtl] .mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}
[dir=rtl] .ml-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}
[dir=rtl] .mr-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}
[dir=rtl] .ml-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}
[dir=rtl] .mr-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}
[dir=rtl] .ml-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}
[dir=rtl] .mr-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
[dir=rtl] .ml-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}
[dir=rtl] .pr-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
[dir=rtl] .pl-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
[dir=rtl] .pr-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}
[dir=rtl] .pl-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}
[dir=rtl] .pr-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}
[dir=rtl] .pl-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}
[dir=rtl] .pr-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}
[dir=rtl] .pl-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}
[dir=rtl] .pr-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}
[dir=rtl] .pl-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}
[dir=rtl] .pr-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}
[dir=rtl] .pl-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

@media (max-width: 959px) {
  [dir=rtl] .edit-text {
    padding-left: 0em;
    padding-right: 0.8rem;
  }
  [dir=rtl] .edit-text::after {
    left: auto;
    right: -12px;
  }
  [dir=rtl] .add-text::after {
    left: auto;
    right: -20px;
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

embryo-brandslogo .slick-slide {
  margin: 1rem 1rem;
}

.slick-track {
  margin-left: inherit !important;
  margin-right: inherit !important;
}