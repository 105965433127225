/* $typography
 ------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  color: $headings-color;
  font-weight: bold;
  margin-top: 0;
}

h1,
.h1 {
  font-size: $font-size-h1;
  color: $h1-font-color;
  font-weight: $headings-font-weight;
}

h2,
.h2 {
  font-size: $font-size-h2;
  color: $h2-font-color;
  font-weight: $headings-font-weight;
}

h3,
.h3 {
  font-size: $font-size-h3;
  color: $h3-font-color;
  font-weight: $headings-font-weight;
}

h4,
.h4 {
  font-size: $font-size-h4;
  color: $h4-font-color;
  font-weight: $headings-font-weight;
}

h5,
.h5 {
  font-size: $font-size-h5;
  color: $h5-font-color;
  font-weight: $headings-font-weight;
}

h6,
.h6 {
  font-size: $font-size-h6;
  color: $h6-font-color;
  font-weight: $headings-font-weight;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
}

strong,
b {
  font-weight: 700;
}
