.user-nav-list{
   box-shadow:$card-shadow;
   border-radius:5px;
   .mat-list-item-content a i {
      vertical-align: top;
      width: 40px;
      text-align: center;
   }
}
.proflie-field span {
	display: inline-block;
	width: calc(100% - 180px);
}
.proflie-field label {
	width: 150px;
	display: inline-block;
}
//login-user-img
.login-user-img img{
   border-radius: 100%;
}
.edit-profile {
   background-repeat: no-repeat;
   background-position: center center;
   opacity: 0.2;
}
//account
.final-receipt-page{
   .account-info{
      .reason-wrap{
         .mat-form-field{
            line-height:.4rem;
         }
      }      
   }
}   
@media (max-width:991px){
   .account-info{
      .user-info{
         margin-bottom:1rem;    
      }  
   }
}