/*=============|Sidebar|============*/

.admin-panel, .admin-panel body{
   height: 100%;
}
.mat-drawer-transition .mat-drawer-content{
   transition: margin 0.4s ease 0s !important;
}
//Sidebar vars
$sidebar-logo-align:center;
$sidebar-logo-padding:1rem 0 0.5rem;
$sidebar-icons:1.2rem;
$user-name-font-size:1rem;


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   .admin-panel body{ 
      mat-sidenav .sidebar-panel .sub-menu, .mat-list-item .menu-caret{
         transition: 0 !important;
      } /* IE11 */
   }   
}
.admin-panel{
   .mat-card{
      margin: 0.875rem;
   }
   .table th, .table td{
      vertical-align: middle;
   }
   body {

      .sidebar-logo.mat-toolbar {
         align-items: center;
         background: transparent none repeat scroll 0 0;
         justify-content: center;
         padding: 0 6px;
      }
      .app {
         height: 100%;
         overflow: hidden;
      }
      .mat-sidenav {
         overflow-x: hidden;
         overflow-y: hidden;
         min-width: 4rem;
      }
      .embryo-logo {
         padding: 2rem;
         text-align: center;
         .logo-text {
            margin: 0 1rem;
         }
      }
      mat-sidenav .sidebar-panel .mat-nav-list a {
         display: flex;
         flex-direction: row;
         align-items: center;
         height: 3.25rem;
         padding: 0 1.5rem;
         font-weight:400;
      }
      mat-sidenav .sidebar-panel .mat-nav-list a {
         color: #fff;
         outline: none;
      }
      mat-sidenav .sidebar-panel .sub-menu {
         padding-top: 0;
         overflow: hidden;
         transition: all 0.4s ease 0s;
         transition-property: max-height;
         max-height: 0;
         a {
            padding-left: 4rem;
            margin: 0;
            padding-top:0.25rem;
            padding-bottom: 0.25rem;
         }
         // .mat-list-item-content {
         // 	margin-bottom: 0 !important;
         // }
      }
      mat-sidenav .sidebar-panel .open .sub-menu {
         max-height: 1000px;
      }
      .embryo-container-wrap {
         height: 100%;
      }
      .mat-sidenav-content {
         height: 100%;
         overflow: hidden;
      }
      mat-sidenav .sidebar-panel .navigation mat-icon:not(.caret) {
         margin-right: 0.75rem;
      }
      .sidebar-panel {
         width: 200px;
         height: 100%;
      }
      .sidebar-container .mat-nav-list .mat-list-item .mat-list-item-content {
         height: auto;
         display: block;
         margin-bottom: 0;
         padding: 0;
      }
      .mat-nav-list .mat-list-item-content:hover,
      .mat-nav-list .mat-list-item-content.mat-list-item-focus,
      .active-link {
         background-color: rgba(14, 27, 121, .7);
      }
      .sidebar-panel .material-icons {
         font-size: 1.4rem;
      }
      .mat-list-item .menu-caret {
         transition: all 0.4s ease 0s;
         margin-right: 0 !important;
      }
      .mat-list-item.open .menu-caret {
         transform: rotate(90deg);
      }
      .embryo-user-thumb {
         padding: 0.9375rem 0;
      }
      .embryo-header-toolbar{
         box-shadow: -2px -42px 34px 21px $primary;
         .secondary-menu {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
         }
      }
      .admin-panel-header{
         .list-flex{
            padding:0px;
         }
         .mat-form-field-appearance-legacy{
            .mat-form-field-wrapper {
               padding-bottom: 1em;
            }   
            .mat-form-field-infix{
               padding:0.9375em 0;
            }    
         }
      }      
      .secondary-menu button {
         height: 50px;
         margin: 0 0.3rem;
         min-width: 50px;
         width: 50px;
         padding: 0;
      }
      .mat-menu-content {
         .mat-menu-item {
            font-size: 14px !important;
            height: 38px !important;
            line-height: 38px !important;
         }
      }
   }
   .embryo-base-container {
      height: calc(100% - 80px);
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
   }
   .embryo-container{
      padding: 2rem 0.5rem 0.5rem 0.5rem;
   }
   .footer-wrap{
      margin-bottom: -0.5rem;
      align-self: flex-end;
   }
   .sidebar-area.mat-sidenav {
      background-color:$primary;
      height: 100%;
   }
   .sidebar-container {
      height: calc(100% - 64px);
   }
   .mat-sidenav-container .mat-nav-list .mat-list-item {
      height: auto;
   }
   body {
      .mat-form-field-appearance-legacy .mat-form-field-label,
      .mat-form-field-appearance-legacy .mat-hint {
         color: rgba(0, 0, 0, .38) !important;
      }
      .mat-form-field-appearance-legacy .mat-form-field-underline {
         background-color: rgba(0, 0, 0, 0.12);
      }
      .mat-form-field-appearance-legacy .mat-form-field-infix {
         font-size: 0.875rem;
      }
      .mat-form-field.mat-focused .mat-form-field-ripple {
         background-color: $primary;
      }
      .mat-mini-fab .mat-button-wrapper {
         display: inline;
      }
   }
   .embryo-img-table {
      padding-top: 2rem !important;
   }
   @media(max-width:1525px) {
      .embryo-mail-wrapper .mat-list .mat-list-item .mat-list-item-content,
      .embryo-mail-wrapper .mat-nav-list .mat-list-item .mat-list-item-content {
         height: 30px;
      }
   }
   @media(max-width:1279px) {
      .boxed-layout-md {
         display: none;
      }
      .embryo-breadcrumbs {
         display: none;
      }
   }
   @media(max-width:959px) {   
      .embryo-header-toolbar {
         .search-bar,
         .mat-icon-button {
            display: none;
         }
      }
   }
   @media(max-width:959px) {
      body {
         .mat-drawer-container {
                  -webkit-overflow-scrolling:auto !important;
         }
         .sidebar-container {
            overflow-x: hidden;
            overflow-y: auto;

         }
      }
   }
   .sidebar-label{
      position: absolute;
      background: red;      
      color: #fff;      
      padding: 0.1rem 0.2rem;      
      border-radius: 5px;      
      font-size: 0.6rem;      
      top: 31%;      
      right: 45px;      
      letter-spacing: 1px;
   }
   @media(max-width:599px){
      body .secondary-menu button{
         min-width: 34px;
         width: 34px;
         .material-icons{
            font-size: 22px;
         }
      }
      .embryo-header-toolbar.mat-toolbar-row, .embryo-header-toolbar.mat-toolbar-single-row {
         height: auto;        
         min-height: 56px;
         .navbar-brand{
            max-width: 125px;
            white-space: normal;
         }
      }
   }
}
.chart-shadow canvas{
   -webkit-filter: drop-shadow(0 8px 8px #A9A9A9);
   filter: drop-shadow(0 8px 8px #A9A9A9);
}
.card-spinner.reload {
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   pointer-events: none;
   background-color: rgba(255,255,255,0.85);
}