@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();

// Define the default theme (same as the example above).
$my-theme-primary: mat-palette($mat-indigo,800);
$my-theme-accent:  mat-palette($mat-deep-orange, 500);
$my-light-theme:   mat-light-theme($my-theme-primary, $my-theme-accent);

// Include the default theme styles.
@include angular-material-theme($my-light-theme);


// Define an alternate dark theme.
$dark-primary: mat-palette($mat-blue-grey);
$dark-accent:  mat-palette($mat-amber, A200, A100, A400);
$dark-warn:    mat-palette($mat-deep-orange);
$dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.unicorn-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.unicorn-dark-theme {
  @include angular-material-theme($dark-theme);
}


//Custom Raised Icon Button colors


// Create your Sass color vars (will be available in all the project)
$primary: mat-color($my-theme-primary);
$accent: mat-color($my-theme-accent);

.primary-color{
  color: $primary;
}
.primary-bg{
  background: $primary;
}
.accent-color{
  color: $accent;
}

.accent-bg{
  background: $accent;
}


